/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 12, 2019 */



@font-face {
    font-family: 'arimobold';
    src: url('arimo-bold-webfont.woff2') format('woff2'),
         url('arimo-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'arimoregular';
    src: url('arimo-regular-webfont.woff2') format('woff2'),
         url('arimo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

button,
input {
  outline: none;
}

.button {
  cursor: pointer;
}

.alert-radio-label.sc-ion-alert-md {
  padding-left: 2em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

ion-popover {
   div.popover-content {
     top: 0% !important;
     // left: 50% !important;
     //  transform: translate(-50%, -50%) !important;
   }
 }

 
 ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}


@-webkit-keyframes autofill {
  to {
    color: #000;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

form {
  --ion-color-success: --ion-color-medium;
  --ion-color-danger: --ion-color-medium;
}

.popover-90 {
  --width: 90%;
}

.max-width {
  max-width: 940px;
  margin: 0 auto;
  width: 100%;
}

.max-content {
  max-width: 463px;
  margin: 0 auto;
  width: 100%;
}

.bk-primary {
  background-color: var(--ion-color-primary);
}
